import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';

const fontBaseSize = 21;
const styles = (theme) => ({
  inline: {
    display: 'inline',
  },
  title: {
    color: '#ba0000',
  },

  verseNumber: {
    color: '#ba0000',
  },
  verseText: {
    color: 'black',
    fontSize: fontBaseSize,
  },
  ellenTitle: {
    color: '#ba0000',
    fontWeight: 'bold',
    fontSize: fontBaseSize,
  },
  ellenComment: {
    color: 'black',
    display: 'block',
    fontSize: fontBaseSize - 4,
  },
  ellenSource: {
    color: 'black',
    fontWeight: 'bold',
    display: 'block',
    fontSize: fontBaseSize - 4,
  },
  ellenBorder: {
    borderWidth: 2,
    borderStyle: 'inset',
    borderColor: '#3b0101',
    padding: 10,
  },
});


class Chapter extends Component {
  render() {
    const { classes, verse, searchScreen } = this.props;
    let chapter;
    if (searchScreen) { chapter = verse.uniqId.split('-')[1]; }

    return (

      <div>
        {verse.title && <h2 className={classes.title}>{verse.title}</h2>}
        <p>
          {!searchScreen
            && (
              <span className={classes.verseNumber}>
                {verse.n}
                {' '}
                -
                {' '}
              </span>
            )}

          <span className={classes.verseText}>{verse.t}</span>
          {searchScreen
            && (
              <Link
                style={{ textDecoration: 'none', color: 'blue' }}
                to={{ pathname: `/leitor/${verse.bn}/${chapter}/${verse.n}` }}
              >
                {` ${verse.bn} ${chapter}:${verse.n}`}
              </Link>
            )}
        </p>

        {verse.e
          && (
            <div className={classes.ellenBorder}>
              {' '}
              {verse.e.map((ellen, i) => (
                <div key={i}>
                  {ellen.ellenWhiteTitle
                    && <span className={classes.ellenTitle}>{ellen.ellenWhiteTitle}</span>}
                  {ellen.ellenWhiteComment
                    && <span className={classes.ellenComment}>{ellen.ellenWhiteComment}</span>}
                  {ellen.ellenWhiteSource
                    && <span className={classes.ellenSource}>{ellen.ellenWhiteSource}</span>}
                  <br />
                </div>
              ))}
            </div>
          )}

      </div>

    );
  }
}

export default withRouter(withStyles(styles)(Chapter));
