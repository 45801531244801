import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import Typography from '@material-ui/core/Typography';
import withWidth, { } from '@material-ui/core/withWidth';
import BookList from '../biblia/list';

const styles = (theme) => ({
  inline: {
    display: 'inline',
  },
  link: {
    display: 'block',
    color: 'red',
  },
});


function BookLista(props) {
  const getGridListCols = () => {
    if (props.props.width === 'xs' || props.props.width === 'sm') {
      return 1;
    }
    return 2;
  };


  return (
    <GridList
      cellHeight="auto"
      spacing="2"
      cols={getGridListCols()}
    >
      <Typography variant="h5" color="secondary" gutterBottom>
        {props.title}
      </Typography>
      <p />
      {props.bookList.map((item) => (
        <Link
          style={{ textDecoration: 'none' }}
          onClick={() => {
            props.goToBook(item.bookName);
          }}
          to={{ pathname: `/leitor/${item.bookName}/1/1` }}
        >
          <span style={{
            display: 'block',
            color: 'black',
            fontSize: 18,
            paddingBottom: 10,
            paddingLeft: 0,
          }}
          >
            {item.bookName}

          </span>
        </Link>
      ))}
    </GridList>
  );
}

class BookListFooter extends Component {
  render() {
    const { classes, goToBook } = this.props;

    return (
      <Grid container spacing={10} justify="center" alignItems="baseline">
        <Grid item xs={6} className={classes.flex}>
          <BookLista
            title="Velho Testamento"
            bookList={BookList.filter((item) => !item.newTestament)}
            goToBook={goToBook}
            props={this.props}
          />
        </Grid>
        <Grid item xs={6} className={classes.flex}>
          <BookLista
            title="Novo Testamento"
            bookList={BookList.filter((item) => item.newTestament)}
            goToBook={goToBook}
            props={this.props}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(withWidth()(BookListFooter)));
