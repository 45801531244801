import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import styles from '../styles/main'
import Topbar from "../components/Topbar";
import Verse from '../components/Verse';
import Grid from "@material-ui/core/Grid";
import LinearProgress from '@material-ui/core/LinearProgress';

class Search extends Component {
    constructor(props) {
        super(props)
        this.state = { isLoading: false }
    }

    componentDidMount() {
        this.update()
    }

    componentDidUpdate(previousProps, previousState) {
        if (previousState?.query !== this.props.match.params.query) {
            this.update()
        }
    }

    update() {
        const { query } = this.props.match.params;
        console.log('Termo de pesquisa', query)
        //Se tem livro capitulo e verso.
        if (query) {

            fetch(
                `https://api.bibliaegw.com/biblia/search?criteria=${query}`,
                {
                    method: "GET",
                    headers: new Headers({})
                }
            )
                .then(res => res.json())
                .then(response => {

                    this.setState({
                        searchResponse: response,
                        query: query,
                        isLoading: false
                    })
                }
                )
                .catch(error => console.log(error));
        }
    }

    render() {
        console.log('I was triggered during render search')
        const { classes } = this.props;
        const { isLoading } = this.state;

        return (
            <React.Fragment>
                <Topbar />


                <Grid container justify="center">
                    <Grid
                        spacing={4}
                        justify="center"
                        container
                        className={classes.grid}
                    >
                        <Grid item xs={12} md={9}>
                            {
                                isLoading && <LinearProgress color="secondary" />
                            }
                            {this.state.query && <h2>Busca por: "{this.state.query}"</h2>}
                            {
                                !isLoading && this.state.searchResponse?.length > 0 &&
                                <div>
                                    {
                                        this.state.searchResponse?.length > 0 &&
                                        this.state.searchResponse.map((verse, i) => (
                                            <Verse key={i} verse={verse} searchScreen={true}></Verse>
                                        ))
                                    }
                                </div>

                            }
                        </Grid>

                    </Grid>
                </Grid>
            </React.Fragment>

        )
    }
}

export default withRouter(withStyles(styles)(Search));