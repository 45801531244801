import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import withStyles from '@material-ui/styles/withStyles';
import Verse from './Verse';

const styles = (theme) => ({
  inline: {
    display: 'inline',
  },
});


class Chapter extends Component {
  render() {
    const { chapter } = this.props;

    return (

      <div>
        {
                    chapter
                    && chapter.verses.map((verse) => (
                      <Verse verse={verse} />
                    ))
                }
      </div>

    );
  }
}

export default withRouter(withStyles(styles)(Chapter));
