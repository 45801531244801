import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Link, withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import BibleList from '../biblia/list'
import Topbar from "../components/Topbar";
import Button from "@material-ui/core/Button";
import BookListFooter from '../components/BookListFooter';
import Chapter from '../components/Chapter';
import styles from '../styles/main'
import THEME from '../styles/theme'
import LinearProgress from '@material-ui/core/LinearProgress';
import { Helmet } from 'react-helmet'
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import ProgressBar from "react-content-progress-bar";
var deepEqual = require('deep-equal')

class Main extends Component {
  constructor(props) {
    super(props)
    this.contentRef = React.createRef();
    this.state = { isLoading: false, errorInFetch: false }
  }

  async setStateAsync(state, that) {
    return new Promise((resolve) => {
      that.setState(state, resolve);
    });
  }

  async componentDidMount() {
    console.log('componentDidMount: ');
    await this.update()
  }

  async componentDidUpdate(previousProps, previousState) {

    const stateIsEqual = deepEqual(previousState.selectedChapter, this.state.selectedChapter)
    // console.log('previousState: ', previousState);
    // console.log('this.state: ', this.state);
    // console.log('É IGUALLLL: ', stateIsEqual);
    // console.log('this.state.errorInFetch: ', this.state.errorInFetch);
    //se previous error era true e atual é true não precisa atualizar
    if (stateIsEqual) {


      if (((previousState?.selectedBook?.bookName !== this.props.match.params.book) ||
        (previousState?.selectedChapter?.number !== parseInt(this.props.match.params.chapter, 10))) &&
        !this.state.errorInFetch
      ) {
        // console.log('------COMPONENTE DID ATUALIZADO A')
        await this.update()
        // console.log('------COMPONENTE DID ATUALIZADO B')
      }
    }
  }

  previousChapter() {
    this.setState({
      isLoading: true,
      errorInFetch: false
    }, () => {
      this.props.history.push(`/leitor/${this.state.selectedBook.bookName}/${this.state.selectedChapter.number - 1}/1`);
    })
  }

  nextChapter() {
    this.setState({
      isLoading: true,
      errorInFetch: false
    }, () => {
      this.props.history.push(`/leitor/${this.state.selectedBook.bookName}/${this.state.selectedChapter.number + 1}/1`);
    })
  }

  goToBook(book) {
    this.setState({
      isLoading: true,
      errorInFetch: false
    })
  }

  goToChapter(number) {
    this.setState({
      isLoading: true,
      errorInFetch: false
    })
  }

  async update() {
    // console.log('inicio update')
    const { book, chapter, verse } = this.props.match.params;
    //Se tem livro capitulo e verso.
    if (book && chapter && verse) {
      const currentChapter = parseInt(chapter, 10)

      try {

        const result = await fetch(`https://api.bibliaegw.com/biblia?livro=${book}&capitulo=${currentChapter}`)
        const resultBody = await result.json();

        const bookDetails = BibleList.find(i => i.bookName.toLowerCase() === book.toLowerCase()) || []
        const chapters = []
        for (let i = 1; i <= bookDetails.chapters; i++) {
          chapters.push({ number: i })
        }

        // console.log('quase no fim update')
        return await this.setStateAsync({
          selectedChapter: resultBody,
          selectedBook: { bookName: book, chapters },
          isLoading: false,
          errorInFetch: false,
        }, this);

        // this.setState({
        //   selectedChapter: resultBody,
        //   selectedBook: { bookName: book, chapters },
        //   isLoading: false,
        //   errorInFetch: false,
        // }, () => console.log('=======fim do set state dentro do update'))


      } catch (error) {
        console.log(error)
        this.setState({
          errorInFetch: true,
          isLoading: false
        })
      }
    }
  }


  render() {
    // console.log('I was triggered during render')
    const { classes } = this.props;
    const { selectedBook, selectedChapter, isLoading, errorInFetch } = this.state;
    return (
      <React.Fragment>
        {selectedChapter &&
          <Helmet>
            <title>{`${selectedBook?.bookName} Capítulo ${selectedChapter?.number} - Bíblia com comentários de Ellen White`}</title>
            <meta name="theme-color" content="#FFFFFF" />
            <meta name="description" content="Bíblia Online com comentários da profetiza Adventista do sétimo dia Ellen Gould White" />
          </Helmet>

        }
        <CssBaseline />
        <Topbar />
        <ProgressBar
          backgroundColor="rgba(125, 125, 125, 0.8)"
          color={THEME.mainColor}
          radius={2}
          contentRef={this.contentRef}
          style="solid"
          thickness={4}
        />
        <div className={classes.root}>

          <Grid container justify="center">

            <Grid
              spacing={4}
              justify="center"
              container
              className={classes.grid}
            >

              <Box clone order={{ xs: 2, md: 1 }}>
                {/* Leitor */}
                <Grid item xs={12} md={9}>
                  {
                    isLoading && <LinearProgress color="secondary" />
                  }

                  {
                    errorInFetch &&
                    <Alert variant="outlined" severity="error">
                      Houve um erro — Por favor, tente novamente ou recarregue a página!
                    </Alert>
                  }

                  {
                    !isLoading &&
                    <Paper className={classes.paper} ref={this.contentRef}>
                      <Typography
                        style={{ textTransform: "uppercase" }}
                        color="secondary"
                        gutterBottom
                        variant='h4'
                      >
                        {selectedBook &&
                          <span className={classes.bookTitle}>{`${selectedBook.bookName} ${selectedChapter.number}`}</span>}
                      </Typography>
                      <Chapter chapter={selectedChapter}></Chapter>

                      <div className={classes.alignRight}>

                        <Button
                          onClick={this.previousChapter.bind(this)}
                          // variant="outlined"
                          color="secondary"
                          variant="contained"
                          className={classes.actionButtom}
                          disabled={!(selectedChapter && (selectedChapter.number > 1))}
                        >
                          Anterior
                       </Button>

                        <Button
                          onClick={this.nextChapter.bind(this)}
                          color="secondary"
                          variant="contained"
                          className={classes.actionButtom}
                          disabled={!(selectedChapter && (selectedChapter.number < selectedBook.chapters.length))}
                        >
                          Próximo
                    </Button>


                      </div>
                    </Paper>
                  }
                </Grid>

              </Box>
              <Box clone order={{ xs: 1, md: 2 }}>
                {/* Lista de capitulos */}
                <Grid item xs={12} md={3}>
                  <Typography
                    style={{ textTransform: "uppercase" }}
                    color="secondary"
                    gutterBottom
                    variant='h6'
                  >
                    {selectedBook && 'Capítulos'}
                  </Typography>

                  <ul className={classes.ul}>
                    {selectedBook &&
                      selectedBook.chapters.map((item) => (
                        <li className={classes.li} >
                          <Link className={classes.link}
                            onClick={() => {
                              this.goToChapter(item.number)
                            }}
                            to={{ pathname: `/leitor/${selectedBook.bookName}/${item.number}/1` }}
                          >
                            {item.number === selectedChapter.number ? `[${item.number}]` : item.number}
                          </Link>
                        </li>
                      ))
                    }
                  </ul>
                </Grid>
              </Box>



              <Box clone order={{ xs: 3, md: 3 }}>
                {/* Lista de livros */}
                <Grid container item xs={12}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}>
                      <div>
                        <BookListFooter goToBook={this.goToBook.bind(this)} />
                      </div>
                    </Paper>
                  </Grid>
                </Grid>

              </Box>


            </Grid>

          </Grid>

        </div>
      </React.Fragment >
    );
  }
}

export default withRouter(withStyles(styles)(Main));
