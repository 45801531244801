import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import './App.css';
// import { blue, indigo } from '@material-ui/core/colors';
import Routes from './routes';

const theme = createMuiTheme({
  palette: {
    secondary: {
      // main: blue[900],
      main: '#800000',
    },
    primary: {
      // main: '#9a0036',
      main: '#ffffff',
      // main: '#dc004e',
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif',
    ].join(','),
  },
});


class App extends Component {
  render() {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
