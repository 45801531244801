const Menu = [
  {
    label: 'Início',
    pathname: '/',
  },
  // {
  //   label: 'Leitor',
  //   pathname: '/',
  // },
  // {
  //   label: 'Entrar',
  //   pathname: '/signup',
  // },
  // {
  //   label: 'Pesquisa',
  //   pathname: '/wizard',
  // },
  // {
  //   label: 'Temas',
  //   pathname: '/cards',
  // },
  // {
  //   label: 'Link Externo',
  //   pathname: 'https://github.com/alexanmtz/material-sense',
  //   external: true,
  // },

];

export default Menu;
