import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import App from './App';
import * as serviceWorker from './serviceWorker';


const trackingId = 'UA-167458014-1'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.hash }); // Update the user's current page
  //   ReactGA.pageview(location.hash, undefined, document.title); // Record a pageview for the given page
  ReactGA.pageview(location.hash); // Record a pageview for the given page
});


ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
