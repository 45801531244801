const genesis = {
  bookName: 'Gênesis',
  file: '1-genesis',
  chapters: 50,
};
const exodo = {
  bookName: 'Êxodo',
  file: '2-exodo',
  chapters: 40,
};
const levitico = {
  bookName: 'Levítico',
  file: '3-levitico',
  chapters: 27,
};
const numeros = {
  bookName: 'Números',
  file: '4-numeros',
  chapters: 36,
};
const deuteronomio = {
  bookName: 'Deuteronômio',
  file: '5-deuteronomio',
  chapters: 34,
};
const josue = {
  bookName: 'Josué',
  file: '6-josue',
  chapters: 24,
};
const juizes = {
  bookName: 'Juízes',
  file: '7-juizes',
  chapters: 21,
};
const rute = {
  bookName: 'Rute',
  file: '8-rute',
  chapters: 4,
};
const iSamuel = {
  bookName: 'I Samuel',
  file: '9-i-samuel',
  chapters: 31,
};
const iiSamuel = {
  bookName: 'II Samuel',
  file: '10-ii-samuel',
  chapters: 24,
};
const IReis = {
  bookName: 'I Reis',
  file: '11-i-reis',
  chapters: 22,
};
const IIReis = {
  bookName: 'II Reis',
  file: '12-ii-reis',
  chapters: 25,
};
const ICronicas = {
  bookName: 'I Crônicas',
  file: '13-i-cronicas',
  chapters: 29,
};
const IICronicas = {
  bookName: 'II Crônicas',
  file: '14-ii-cronicas',
  chapters: 36,
};
const Esdras = {
  bookName: 'Esdras',
  file: '15-esdras',
  chapters: 10,
};
const Neemias = {
  bookName: 'Neemias',
  file: '16-neemias',
  chapters: 13,
};
const Ester = {
  bookName: 'Ester',
  file: '17-ester',
  chapters: 10,
};
const Jo = {
  bookName: 'Jó',
  file: '18-jo',
  chapters: 42,
};
const Salmos = {
  bookName: 'Salmos',
  file: '19-salmos',
  chapters: 150,
};
const Proverbios = {
  bookName: 'Provérbios',
  file: '20-proverbios',
  chapters: 31,
};
const Eclesiastes = {
  bookName: 'Eclesiastes',
  file: '21-eclesiastes',
  chapters: 12,
};
const Cantares = {
  bookName: 'Cantares',
  file: '22-cantares',
  chapters: 8,
};
const Isaias = {
  bookName: 'Isaías',
  file: '23-isaias',
  chapters: 66,
};
const Jeremias = {
  bookName: 'Jeremias',
  file: '24-jeremias',
  chapters: 52,
};
const Lamentacoes = {
  bookName: 'Lamentações',
  file: '25-lamentacoes',
  chapters: 5,
};
const Ezequiel = {
  bookName: 'Ezequiel',
  file: '26-ezequiel',
  chapters: 48,
};
const Daniel = {
  bookName: 'Daniel',
  file: '27-daniel',
  chapters: 12,
};
const Oseias = {
  bookName: 'Oséias',
  file: '28-oseias',
  chapters: 14,
};
const Joel = {
  bookName: 'Joel',
  file: '29-joel',
  chapters: 3,
};
const Amos = {
  bookName: 'Amós',
  file: '30-amos',
  chapters: 9,
};
const Obadias = {
  bookName: 'Obadias',
  file: '31-obadias',
  chapters: 1,
};
const Jonas = {
  bookName: 'Jonas',
  file: '32-jonas',
  chapters: 4,
};
const Miqueias = {
  bookName: 'Miquéias',
  file: '33-miqueias',
  chapters: 7,
};
const Naum = {
  bookName: 'Naum',
  file: '34-naum',
  chapters: 3,
};
const Habacuque = {
  bookName: 'Habacuque',
  file: '35-habacuque',
  chapters: 3,
};
const Sofonias = {
  bookName: 'Sofonias',
  file: '36-sofonias',
  chapters: 3,
};
const Ageu = {
  bookName: 'Ageu',
  file: '37-ageu',
  chapters: 2,
};
const Zacarias = {
  bookName: 'Zacarias',
  file: '38-zacarias',
  chapters: 14,
};
const Malaquias = {
  bookName: 'Malaquias',
  file: '39-malaquias',
  chapters: 4,
};
const mathew = {
  bookName: 'Mateus',
  file: '1-mateus',
  newTestament: true,
  chapters: 28,
};
const mark = {
  bookName: 'Marcos',
  file: '2-marcos',
  newTestament: true,
  chapters: 16,
};
const luke = {
  bookName: 'Lucas',
  file: '3-lucas',
  newTestament: true,
  chapters: 24,
};
const john = {
  bookName: 'João',
  file: '4-joao',
  newTestament: true,
  chapters: 21,
};
const acts = {
  bookName: 'Atos',
  file: '5-atos',
  newTestament: true,
  chapters: 28,
};
const romanos = {
  bookName: 'Romanos',
  file: '6-romanos',
  newTestament: true,
  chapters: 16,
};
const iCorinthios = {
  bookName: 'I Coríntios',
  file: '7-i-corintios',
  newTestament: true,
  chapters: 16,
};
const iiCorinthios = {
  bookName: 'II Coríntios',
  file: '8-ii-corintios',
  newTestament: true,
  chapters: 13,
};
const galatas = {
  bookName: 'Gálatas',
  file: '9-galatas',
  newTestament: true,
  chapters: 6,
};
const efesios = {
  bookName: 'Efésios',
  file: '10-efesios',
  newTestament: true,
  chapters: 6,
};
const filipenses = {
  bookName: 'Filipenses',
  file: '11-filipenses',
  newTestament: true,
  chapters: 4,
};
const colossenses = {
  bookName: 'Colossenses',
  file: '12-colossenses',
  newTestament: true,
  chapters: 4,
};
const iTessalonicenses = {
  bookName: 'I Tessalonicenses',
  file: '13-i-tessalonicenses',
  newTestament: true,
  chapters: 5,
};
const iiTessalonicenses = {
  bookName: 'II Tessalonicenses',
  file: '14-ii-tessalonicenses',
  newTestament: true,
  chapters: 3,
};
const iTimoteo = {
  bookName: 'I Timóteo',
  file: '15-i-timoteo',
  newTestament: true,
  chapters: 6,
};
const iiTimoteo = {
  bookName: 'II Timóteo',
  file: '16-ii-timoteo',
  newTestament: true,
  chapters: 4,
};
const tito = {
  bookName: 'Tito',
  file: '17-tito',
  newTestament: true,
  chapters: 3,
};
const filemon = {
  bookName: 'Filemom',
  file: '18-filemon',
  newTestament: true,
  chapters: 1,
};
const hebreus = {
  bookName: 'Hebreus',
  file: '19-hebreus',
  newTestament: true,
  chapters: 13,
};
const tiago = {
  bookName: 'Tiago',
  file: '20-tiago',
  newTestament: true,
  chapters: 5,
};
const iPedro = {
  bookName: 'I Pedro',
  file: '21-i-pedro',
  newTestament: true,
  chapters: 5,
};
const iiPedro = {
  bookName: 'II Pedro',
  file: '22-ii-pedro',
  newTestament: true,
  chapters: 3,
};
const iJoao = {
  bookName: 'I João',
  file: '23-i-joao',
  newTestament: true,
  chapters: 5,
};
const iiJoao = {
  bookName: 'II João',
  file: '24-ii-joao',
  newTestament: true,
  chapters: 1,
};
const iiiJoao = {
  bookName: 'III João',
  file: '25-iii-joao',
  newTestament: true,
  chapters: 1,
};
const judas = {
  bookName: 'Judas',
  file: '26-judas',
  newTestament: true,
  chapters: 1,
};
const apocalipse = {
  bookName: 'Apocalipse',
  file: '27-apocalipse',
  newTestament: true,
  chapters: 22,
};


export default [
  genesis,
  exodo,
  levitico,
  numeros,
  deuteronomio,
  josue,
  juizes,
  rute,
  iSamuel,
  iiSamuel,
  IReis,
  IIReis,
  ICronicas,
  IICronicas,
  Esdras,
  Neemias,
  Ester,
  Jo,
  Salmos,
  Proverbios,
  Eclesiastes,
  Cantares,
  Isaias,
  Jeremias,
  Lamentacoes,
  Ezequiel,
  Daniel,
  Oseias,
  Joel,
  Amos,
  Obadias,
  Jonas,
  Miqueias,
  Naum,
  Habacuque,
  Sofonias,
  Ageu,
  Zacarias,
  Malaquias,
  mathew,
  mark,
  luke,
  john,
  acts,
  romanos,
  iCorinthios,
  iiCorinthios,
  galatas,
  efesios,
  filipenses,
  colossenses,
  iTessalonicenses,
  iiTessalonicenses,
  iTimoteo,
  iiTimoteo,
  tito,
  filemon,
  hebreus,
  tiago,
  iPedro,
  iiPedro,
  iJoao,
  iiJoao,
  iiiJoao,
  judas,
  apocalipse,
];
