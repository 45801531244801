export default (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.grey['100'],
    // backgroundColor: 'white',
    overflow: 'hidden',
    paddingBottom: 30,
  },
  bookTitle: {
    color: '#ba0000',
  },
  grid: {
    width: 1200,
    marginTop: 40,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 20px)',
    },
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    // color: theme.palette.grey["100"],
    height: '100%',
  },
  rangeLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 32,
  },
  actionButtom: {
    textTransform: 'uppercase',
    margin: theme.spacing(1),
    width: 152,
  },
  blockCenter: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  block: {
    padding: theme.spacing(2),
  },
  box: {
    marginBottom: 40,
    height: 100,
  },
  inlining: {
    display: 'inline-block',
    marginRight: 10,
  },
  buttonBar: {
    display: 'flex',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  alignLeft: {
    display: 'flex',
    justifyContent: 'flex-begin',
  },
  noBorder: {
    borderBottomStyle: 'hidden',
  },
  loadingState: {
    opacity: 0.05,
  },
  loadingMessage: {
    position: 'absolute',
    top: '40%',
    left: '40%',
  },
  ul: {
    // display: "flex",
    // listStyle: "none",
    margin: 0,
    padding: 0,
    // overflow: 'hidden'
  },
  li: {
    marginBottom: 9,
    paddingRight: 15,
    float: 'left',
    textAlign: 'right',
    fontSize: 18,
    display: 'block',
  },
  link: {
    textDecoration: 'none',
    color: 'black',
  },
});
